<template>
  <div class="poll-container">
    <div v-if="!pollSubmitted">
      <p>{{ pollContent.text }}</p>
      <form @submit.prevent="submitPoll">
        <div class="poll-options-grid">
          <div v-for="(mod, index) in pollContent.mods" :key="index" class="poll-option">
            <input
              type="checkbox"
              :id="mod.name"
              v-model="selectedMods"
              :value="mod.name"
              class="custom-checkbox"
            />
            <label :for="mod.name">{{ mod.name }}</label>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>

    <div v-if="pollSubmitted">
      <div v-for="(mod, index) in pollContent.mods" :key="index" class="result-item" :class="{'highlight-selected': selectedMods.includes(mod.name)}">
        <span class="mod-name">
          {{ mod.name }}: {{ calculatePercentage(mod.votes) }}%
        </span>
        <div class="bar-container">
          <div class="bar" :style="{ width: calculatePercentage(mod.votes) + '%' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pollContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedMods: [], // Changed to an array for multiple selections
      pollSubmitted: false,
      totalVotes: this.pollContent.mods.reduce((acc, mod) => acc + mod.votes, 0), // Sum up total votes
    };
  },
  methods: {
    submitPoll() {
      if (this.selectedMods.length > 0) {
        // Increment the votes for the selected WhatsApp mods
        this.selectedMods.forEach(selectedMod => {
          const selected = this.pollContent.mods.find(mod => mod.name === selectedMod);
          selected.votes += 1;
        });

        this.pollSubmitted = true;

        // Update the total number of votes
        this.totalVotes += this.selectedMods.length; // Update total votes based on selections
      } else {
        alert('Please select at least one mod.');
      }
    },
    calculatePercentage(votes) {
      return this.totalVotes > 0 ? ((votes / this.totalVotes) * 100).toFixed(2) : 0;
    },
  },
};
</script>
